import {StreamType} from './stream-info.model';


export enum EventStatus {
    LOADING = "LOADING",
    NO_ACCESS = "NO_ACCESS",
    PREPARATION_STATE = 'PREPARATION_STATE',
    LIVE_ONAIR = 'LIVE_ONAIR',
    OFF_AIR = 'OFF_AIR'
};

export interface WatchEventStatus {
    status: EventStatus,
	streamType: string
}
