import {AfterViewInit, Component, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {catchError, map, shareReplay, switchMap, takeUntil, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {StreamInfo, StreamType} from '../../models/stream-info.model';
import {VideoRecorderComponent,} from '../../shared/components/video-recorder/video-recorder.component';
import {TranslateService} from '@ngx-translate/core';
import {EventStatus} from '../../models/event-info';
import {EventStatusService} from '../../services/event-status.service';
import {
	createCountdownEndEventStream,
	createCountdownOpenEventStream,
	createEndOfStreamTimeTrigger
} from '../../shared/streams/streamUtils';
import {ViewStatsService} from '../../services/view-stats.service';
import {MatTabGroup} from '@angular/material/tabs';
import {MatTooltip} from '@angular/material/tooltip';
import {VideoPlayerComponent} from '../../shared/components/video-player/video-player.component';
import {CurrentBroadcastService} from '../../shared/services/current-broadcast.service';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {LanguageDetectorService} from '../../services/language-detector.service';
import {EnvService} from '../../services/env.service';


@Component({
	selector: 'app-livecast',
	templateUrl: './livecast.component.pug',
	styleUrls: ['./livecast.component.scss'],
	// TODO: Change the changeDetection to onPush to improve performance
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class LivecastComponent implements OnDestroy, AfterViewInit {
	@ViewChild('streamTypeTabs', {static: false}) tabGroup: MatTabGroup;
	@ViewChild('recorder', {static: false}) recorder: VideoRecorderComponent;
	@ViewChild('tooltip1') _matTooltip: MatTooltip;
	@ViewChild('tooltip2') _matTooltip2: MatTooltip;

	@ViewChild(VideoRecorderComponent, {static: false}) webVideoRecorder: VideoRecorderComponent;

	@ViewChild(VideoPlayerComponent, {static: false}) rtmpVideoPlayer: VideoPlayerComponent;

	EventStates = EventStatus;
	StreamTypes = StreamType;

	tabIndexToStreamType: { [index: number]: StreamType } = {
		0: StreamType.WEB_RECORDER,
		1: StreamType.RTMP,
		2: StreamType.EXTERNAL_STREAM,
		3: StreamType.SIMULIVE,
		4: StreamType.VOD
	};

	streamTypeToTabIndex = {
		'WEB_RECORDER': 0,
		'RTMP': 1,
		'EXTERNAL_STREAM': 2,
		'SIMULIVE': 3,
		'VOD': 4
	};

	generateStreamUrlIndex = {
		0:'START',
		1:'LOADING',
		2: 'FINISHED'
	};

	currentGenerateStreamUrlIndex = 'START';

	streamInfo$: Observable<StreamInfo>;
	rtmpStream = {
		rtmpUrl: '',
		streamName: '',
		hlsEndpoint: '',
	}

	$destroyed = new Subject();

	private renewToken$ = new BehaviorSubject<boolean>(false);
	public modalMessage$ = new BehaviorSubject<string>('');
	public promptEndShow$ = new BehaviorSubject<boolean>(false);

	public countdownToEvent$: Observable<string>;

	public countdownToShowEnd$: Observable<string>;
	public showReachesEndTime$: Observable<boolean>;

	currentDate: number;
	copiedTpDisabled: boolean = true;
	copiedTpDisabled2: boolean = true;

	streamingType: StreamType;
	currentTab: StreamType = StreamType.WEB_RECORDER;

	userToken: string = '';
	streamId: string;

	selectedTabIndex = 0;

	isVideoImported: boolean;

	constructor(public route: ActivatedRoute,
	            public http: HttpClient,
	            private router: Router,
	            public sanitizer: DomSanitizer,
	            public translate: TranslateService,
	            public eventStatusService: EventStatusService,
	            public viewStatsServer: ViewStatsService,
	            public titleService: Title,
	            public currentBroadcastService: CurrentBroadcastService,
	            public languageDetectorService: LanguageDetectorService,
	            public env: EnvService) {

		this.currentDate = Date.now();
		this.streamId = this.route.snapshot.paramMap.get('streamId');
		this.userToken = this.route.snapshot.queryParamMap.get('token');
		this.currentBroadcastService.setCurrentShow('cast', this.streamId, this.userToken);
		this.eventStatusService.joinStatusUpdates(this.streamId, this.userToken, true);
		this.viewStatsServer.joinStatsUpdates(this.streamId);

		let renew = false;
		this.streamInfo$ = this.renewToken$
		                       .pipe(
			                       tap(renewVal => renew = renewVal),
			                       switchMap(() => this.getStreamInfo(this.streamId, this.userToken)),
			                       tap(streamInfo => {
				                       if (this.streamingType === StreamType.WEB_RECORDER && renew) {
					                       // Publish after the Angular update cycle is finished, so the recorder
					                       // params are updated Otherwise the recorder will use the old WS Url to
					                       // publish:
					                       setTimeout(() => this.recorder.publish());
				                       }
			                       }),
			                       takeUntil(this.$destroyed),
			                       shareReplay(1)
		                       );
		this.countdownToEvent$ = createCountdownOpenEventStream(this.eventStatusService, this.translate, this.streamInfo$);
		this.countdownToShowEnd$ = createCountdownEndEventStream(this.eventStatusService, this.translate, this.streamInfo$, 5 * 60);
		this.showReachesEndTime$ = createEndOfStreamTimeTrigger(this.eventStatusService, this.streamInfo$);
	}

	ngAfterViewInit(): void {
	}

	ngOnDestroy() {
		this.$destroyed.next(true);
	}

	getStreamInfo(streamId: string, token: string): Observable<StreamInfo> {
		const url = `${this.env.apiUrl}/api/stream/cast/${streamId}?token=${encodeURIComponent(token)}`;
		return this.http.get<StreamInfo>(url).pipe(
			catchError(() => {
				this.router.navigate(['/notfound']);
				throw new Error('Access denied');
			}),
			map(streamInfo => {
				console.log('WS URL Received: ', streamInfo.wsUrl);
				if (streamInfo.streamType) {
					this.streamingType = this.streamTypeToTabIndex[streamInfo.streamType];
					this.currentTab = this.streamingType;

					this.selectedTabIndex = this.streamingType;
				}
				streamInfo.rtmpUrl = this.env.rtmpUrl;
				streamInfo.playerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
					`${this.env.playerUrl}?streamId=${this.env.millicastAccount}/${streamInfo.eventId}&token=${streamInfo.token}`
				);
				this.titleService.setTitle(streamInfo.title);
				return streamInfo;
			}),
		);
	}

	copyLink(link) {
		link.select();
		document.execCommand('copy');
		link.setSelectionRange(0, 0);
		this.copiedTpDisabled = false;
		setTimeout(() => this._matTooltip.show());
		setTimeout(() => {
			this.copiedTpDisabled = true;
			this._matTooltip.hide();
		}, 2500);
	}

	copyName(name) {
		name.select();
		document.execCommand('copy');
		name.setSelectionRange(0, 0);
		this.copiedTpDisabled2 = false;
		setTimeout(() => this._matTooltip2.show());
		setTimeout(() => {
			this.copiedTpDisabled2 = true;
			this._matTooltip2.hide();
		}, 2500);
	}

	onPublishStream() {
		this.renewToken$.next(true);
		// this.recorder.publish();
	}

	onTabChange(tabIdx) {
		console.log('tab change: ', tabIdx, this.tabGroup.selectedIndex);
		setTimeout(() => this.currentTab = tabIdx, 0);
	}

	onRemoveModal() {
		this.modalMessage$.next('');
	}

	async onChangeStatus(eventId: string, newStatus: EventStatus, oldStatus: EventStatus) {
		const url = `${this.env.apiUrl}/api/event/${eventId}/status`;
		const params: any = {status: newStatus, userToken: this.userToken};

		this.modalMessage$.next('');
		if (oldStatus === EventStatus.LIVE_ONAIR && this.streamingType == StreamType.WEB_RECORDER) {
			this.onStopStream();
		}

		if (newStatus == EventStatus.LIVE_ONAIR) {
			//TODO: Make cleaner
			this.streamingType = this.tabIndexToStreamType[this.tabGroup.selectedIndex];
			params.streamingType = StreamType[this.streamingType];
		}
		this.http.post(url, params).subscribe((resp: any) => {
			if (resp.success) {

				if (newStatus == EventStatus.LIVE_ONAIR) {
					this.onPublishStream();
				}
				this.eventStatusService.updateStatusLocally({status: resp.newStatus, streamType: resp.streamType});

				if (newStatus == EventStatus.OFF_AIR) {
					this.modalMessage$.next('CASTER_SHOW_ENDED_SUCCESSFULLY');
				}
			}
		});
	}

	onRecorderEvent(event, streamInfo: StreamInfo, status: EventStatus) {
		if (event.event === 'ready' && status === EventStatus.LIVE_ONAIR) {
			console.log('*** Resume streaming');
			this.recorder.publish();
		}
	}

	onStopStream() {
		this.recorder.endPublish();
	}

	onVideoImported(value){
		this.isVideoImported = (value.status === 'imported');
	}

	getStreamSource(streamInfo) {
		if (streamInfo.streamType === 'EXTERNAL_STREAM') {
			return streamInfo.sourceUrl;
		}
		return ``;
	}

	generateStreamUrl() {
		const params: any = {userToken: this.userToken};
		this.currentGenerateStreamUrlIndex = this.generateStreamUrlIndex[1];
		this.http.post(`${this.env.apiUrl}/api/event/${this.streamId}/rtmp-channel`, params).subscribe((x: any) => {
			this.rtmpStream.rtmpUrl = this.getStreamNameAndUrl(x.rtmpEndpoint).url;
			this.rtmpStream.streamName = this.getStreamNameAndUrl(x.rtmpEndpoint).streamName;
			this.rtmpStream.hlsEndpoint = x.hlsEndpoint;
			this.currentGenerateStreamUrlIndex = this.generateStreamUrlIndex[2];
		});
	}

	getStreamNameAndUrl(str) {
		const n = str.lastIndexOf('/');
		const url = str.slice(0, n + 1);
		const streamName = str.slice(n + 1, str.length);
		return {url, streamName};
	}
}
