import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import * as PubNub from 'pubnub';
import Pubnub, {HereNowResponse, HistoryResponse, PublishParameters, PublishResponse} from 'pubnub';
import {ChatMessageInfo, ChatMessageToSend} from '../../../models/ChatMessage';
import {ChatPresenceEvent} from '../../../models/ChatPresence';
import {EnvService} from '../../../services/env.service';

export interface PubNubUserInfo {
    displayName: string;
    picURL: string;
    firebaseUID: string;
}

@Injectable({
    providedIn: 'root'
})
export class PubNubService {
    userUid = '';
    pubNub: Pubnub;
    eventId: string = '';
    viewersInAudience: { [userToken: string]: PubNubUserInfo } = {};

    newChatMessage$ = new Subject<ChatMessageInfo>();
    messageHistory$ = new Subject<HistoryResponse>();
    chatUsers$ = new Subject<PubNubUserInfo[]>();

    constructor(private env: EnvService) {
    }

    get tempUID() {
        return 'ANONYMOUS';
    }

    async setupPubNub() {
        const uuid = (!this.userUid || this.userUid === '') ? this.tempUID : this.userUid;
        this.pubNub = new PubNub({
            publishKey: this.env.pubNubPublish,
            subscribeKey: this.env.pubNubSubscribe,
            uuid
        });
    }

    async setUserState(userInfo: PubNubUserInfo) {
        if (!userInfo) return;
        await this.pubNub.setState({
            channels: [this.eventId, this.userUid],
            state: userInfo
        });
    }

    async changeUID(userInfo: PubNubUserInfo) {
        await this.pubNub.setUUID(userInfo.firebaseUID);
        await this.setUserState(userInfo);
        await this.subscribeToPrivateChannel(userInfo.firebaseUID);
    }

    async invokeChat(channel: string, isLogged: boolean, userInfo?: PubNubUserInfo) {
        this.eventId = channel;
        if (isLogged) {
            this.userUid = userInfo.firebaseUID;
        }
        await this.setupPubNub();
        this.listenToMessages();
        await this.subscribePubNubChannel();
        await this.setUserState(userInfo);
    }

    async subscribePubNubChannel() {
        await this.pubNub.subscribe({
            channels: [this.eventId],
            withPresence: true
        });
        if (this.userUid !== '') {
            await this.subscribeToPrivateChannel(this.userUid);
        }
        this.setViewersListOnStart();
    }

    async subscribeToPrivateChannel(uid: string) {
        this.userUid = uid;
        this.pubNub.subscribe({
            channels: [`uid_${this.userUid}.*`], // wildcard subscribe
            // private room template: uid_${userUid}
            withPresence: true
        });
        // this.notifyUsersListChange();
    }

    listenToMessages() {
        let t = this;
        this.pubNub.addListener({
            message: function (event: ChatMessageInfo) {
                t.notifyNewChatMessage(event);
            },
            presence: function (event: ChatPresenceEvent) {
                t.classifyPresenceEvent(event);
            }
        })
    }

    async retrieveMessageHistory(channelName: string) {
        const channel = channelName === 'GENERAL' ? this.eventId : channelName
        // const timeNow = Date.now() * 10000; // epoch nano-seconds
        const msgHistory: HistoryResponse = await this.pubNub.history({
                channel: channel,
                count: 100, // maximum 100 messages
                includeMeta: true,
                // stringifiedTimeToken: true
            }
        );
        this.emitMessageHistory(msgHistory);
    }

    async retrievePrivateChatMessageHistory(channel1Name: string, channel2Name: string) {
        // const timeNow = JSON.stringify(Date.now() * 10000);
        const [res1, res2] = await Promise.all([this.pubNub.history({
            channel: channel1Name,
            count: 100, // maximum 100 messages
            includeMeta: true,
        }), this.pubNub.history({
            channel: channel2Name,
            count: 100, // maximum 100 messages
            includeMeta: true,
        })]);
        this.sortReceivedPrivateMessagesByTime(res1, res2);
    }

    sortReceivedPrivateMessagesByTime(user1: HistoryResponse, user2: HistoryResponse) {
        let messagesObj = {endTimeToken: '', startTimeToken: '', messages: user1.messages.concat(user2.messages)};
        messagesObj.messages.sort((a, b) => {
            if (a.timetoken < b.timetoken) return -1;
            if (a.timetoken > b.timetoken) return 1;
        });
        this.emitMessageHistory(messagesObj);
    }

    async submitMessage(message: ChatMessageToSend, addressee: string) {
        const res: PublishResponse = await this.pubNub.publish(<PublishParameters>{
            channel: addressee ? `${addressee}` : this.eventId,
            message: message.message,
            meta: {
                displayName: message.displayName,
                picURL: message.picURL,
                from: message.from,
                eventId: message.eventId,
                to: message.to
            },
            storeInHistory: true, // enables ttl
            ttl: 3 // time to live: 3 hours
        });
        if (addressee) {
            this.setSelfPrivateMessage(addressee, message, res.timetoken);
        }
    }

    setSelfPrivateMessage(addressee: string, message: ChatMessageToSend, timetoken) {
        const pMessage: ChatMessageInfo = {
            actualChannel: '@deprecated',
            channel: addressee,
            message: message.message,
            publisher: this.eventId,
            subscribedChannel: this.eventId,
            subscription: null,
            timetoken,
            userMetadata: {
                displayName: message.displayName,
                picURL: message.displayName,
                from: message.from,
                eventId: this.eventId,
                to: message.to
            }
        };
        this.notifyNewChatMessage(pMessage);
    }

    // presence events

    classifyPresenceEvent(response: ChatPresenceEvent) {
        switch (response.action) {
            case 'join': // if number of users in the channel is below pubnub configuration: announce max
                this.singleUserJoin(response);
                break;
            case 'leave':
                this.removeFirstUser(response);
                break;
            case 'interval':
                this.connectedUsers(response);
        }
    }

    singleUserJoin(response: ChatPresenceEvent) {
        if (response.channel === this.eventId) {
            if (response.uuid === this.tempUID) return;
            if (!this.viewersInAudience[response.uuid] && response.state) {
                this.viewersInAudience[response.uuid] = response.state;
            }
            this.convertViewersListToAnArray();
        }
    }

    removeFirstUser(response: ChatPresenceEvent) {
        if (response.channel === this.eventId) {
            if (response.uuid === this.tempUID) return;
            if (this.viewersInAudience[response.uuid]) {
                delete this.viewersInAudience[response.uuid];
                this.convertViewersListToAnArray();
            }
        }
    }

    connectedUsers(intervalResponse: ChatPresenceEvent) {
        if (intervalResponse.channel !== this.eventId) return;
        if (intervalResponse.leave && intervalResponse.leave.length > 0) {
            intervalResponse.leave.forEach(usr => {
                if (this.viewersInAudience[usr]) {
                    delete this.viewersInAudience[usr]
                }
            })
        }
        if (intervalResponse.join && intervalResponse.join.length > 0) {
            this.addInfoToNewUsers(intervalResponse.join).then();
            return;
        }

        // case: users left channel and no one joined
        if (intervalResponse.leave && intervalResponse.leave.length > 0) {
            this.convertViewersListToAnArray();
        }
    }

    setViewersListOnStart() {
        this.getWhoIsInTheChannel().then(r => {
            if (r.channels[this.eventId] && r.channels[this.eventId].occupants.length > 0) {
                for (let user of r.channels[this.eventId].occupants) {
                    if (user.state && user.uuid !== this.tempUID) {
                        this.viewersInAudience[user.uuid] = user.state;
                    }
                }
                this.convertViewersListToAnArray();
            }
        });
    }

    async addInfoToNewUsers(users: string[]) {
        const usersInTheChannel: HereNowResponse = await this.getWhoIsInTheChannel();
        const usersArray = usersInTheChannel.channels[this.eventId].occupants.filter(x => x.uuid !== this.tempUID && x.uuid !== '');
        let userToRetrieveState = [];
        users.forEach(usr => {
            const userData = usersArray.find(x => x.uuid === usr);
            if (userData && userData.uuid !== this.tempUID) {
                this.viewersInAudience[usr] = {...userData.state}
            }
            if (!userData && usr !== this.tempUID) {
                userToRetrieveState.push(usr);
            }
        })

        if (userToRetrieveState.length > 0) {
            const usrState = await this.pubNub.getState({
                uuid: userToRetrieveState[0],
                channels: [this.eventId]
            })
            this.viewersInAudience[userToRetrieveState[0]] = usrState.channels[this.eventId];
            if (userToRetrieveState.length > 1) {
            }
        }

        this.removeAnonymousUsersFromAudience();
        this.convertViewersListToAnArray();
    }

    removeAnonymousUsersFromAudience() {
        const uuidsArray = Object.keys(this.viewersInAudience);
        uuidsArray.forEach(uuid => {
            if (this.viewersInAudience[this.tempUID]) {
                delete this.viewersInAudience[uuid];
            }
        })
    }

    getWhoIsInTheChannel(): Promise<HereNowResponse> {
        return this.pubNub.hereNow({
            channels: [this.eventId],
            includeUUIDs: true,
            includeState: true
        });
    }

    convertViewersListToAnArray() {
        const usersList: PubNubUserInfo[] = Object.keys(this.viewersInAudience).map((value) => this.viewersInAudience[value]);
        this.emitChatUsers(usersList);
    }

    // emit events

    notifyNewChatMessage(newMsg: ChatMessageInfo) {
        this.newChatMessage$.next(newMsg);
    }

    emitMessageHistory(msgHistory: HistoryResponse) {
        this.messageHistory$.next(msgHistory)
    }

    emitChatUsers(usersList: PubNubUserInfo[]) {
        this.chatUsers$.next(usersList)
    }

    // unSubscribe

    unSubscribeAllChannels() {
        this.pubNub.unsubscribeAll();
        this.pubNub.removeListener(this.pubNub);
    }

    // private message receipts
    // we might need this in the future

    // setPrivateMessageAsRead(channel: string, messageTimetoken: string) {
    //     this.pubNub.addMessageAction(
    //         {
    //             channel,
    //             messageTimetoken,
    //             action: {
    //                 type: 'receipt',
    //                 value: 'message_read',
    //             },
    //         },
    //         function (status, response) {
    //         }
    //     );
    // }
    //
    // getPrivateMessageReceipt(channel: string) { // get only the last message
    //     const timeNow = JSON.stringify(Date.now() * 10000); // epoch nano-seconds
    //     this.pubNub.getMessageActions({
    //             channel,
    //             start: timeNow,
    //             // includeMessageActions: true,
    //             limit: 1
    //         },
    //         function (status, response) {
    //             if (status.error) {
    //                 // handle error
    //             } else {
    //             }
    //         }
    //     );
    // }
}
