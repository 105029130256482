import {
    AfterViewInit,
    Component,
    ElementRef, EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {GoogleStorageService} from '../../../../services/google-storage.service';
import {ActivatedRoute} from '@angular/router';
import {EmojiSearch} from '@ctrl/ngx-emoji-mart';
import UserCredential = firebase.auth.UserCredential;
import {ChatService, SCROLL_CHANGE_TYPE} from '../chat.service';
import {LanguageDetectorService} from '../../../../services/language-detector.service';

@Component({
    selector: 'app-chat',
    templateUrl: './chat.component.pug',
    styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() streamId: string;
    @Output() isChatStarted = new EventEmitter<boolean>();
    @ViewChild('chatElement', {static: false}) chatElement: ElementRef;
    @ViewChild('chatInput', {static: false}) chatInput: ElementRef;

    // @ViewChild('newChatMessage', {static: false}) newChatMessage: ElementRef;

    @HostListener('window:resize', ['$event'])
    onResize() {
        const newWindowHeight = window.innerHeight;
        // if (this.windowHeight * 1.2 < newWindowHeight || newWindowHeight < this.windowHeight * 0.8) {
        //     this.windowHeight = window.innerHeight;
        //     this.setChatHeight();
        // }
        this.setChatHeight();

    }

    isMobileResolution = false;
    isUserLogged: boolean;
    isLogginPhase: boolean;
    isEmojiWindowOpen: boolean;
    caretPosition = 0;
    newMessage = '';

    pageType: string;
    windowHeight: number;
    mobileChatHeight: number = 0;
    audienceHeight: number = 0;

    // sheet: '' | 'apple' | 'facebook' | 'twitter' | 'google' = 'twitter';
    // size = 22;
    // sheetSize: 16 | 20 | 32 | 64 = 64;
    // sheetRows = 57;
    // sheetColumns = 57;
    backgroundUrl = 'https://unpkg.com/emoji-datasource-${set}@5.0.1/img/${set}/sheets-256/${sheetSize}.png';

    domObserver: MutationObserver;

    constructor(
        public translate: TranslateService,
        private googleStorageService: GoogleStorageService,
        public route: ActivatedRoute,
        // private emojiSearch: EmojiSearch,
        public chatService: ChatService,
        public languageDetectorService: LanguageDetectorService
    ) {
        this.isMobileResolution = window.innerWidth < 900;
        this.route.url.subscribe(url => {
            this.pageType = url[0].path
            this.chatService.setIsPrivateChatMode(false);
        });
        this.chatService.setUserEventToken(this.route.snapshot.queryParams.token);
    }

    ngOnInit(): void {
        this.chatService.setEventId(this.streamId);
        this.windowHeight = window.innerHeight;
        this.invokeChat().then();
    }

    ngAfterViewInit(): void {
        this.chatInput.nativeElement.addEventListener('keyup', (key: KeyboardEvent) => this.keyPressed(key));
    }

    async invokeChat() {
        await this.fetchUserDataFromServer();
        setTimeout(_ => {
            this.setChatHeight();
            this.listenToChatInputDOMChanges();
            this.scrollToBottom(SCROLL_CHANGE_TYPE.NEW);
        }, 1000);
        this.chatService.checkIfScrollDownNeeded$.subscribe(mode => this.scrollToBottom(mode));
        this.isChatStarted.emit(true);
        this.chatService.isPrivateChatMode$.subscribe(isPrivateMode => {
            if (this.isMobileResolution) this.setMobileChatHeightOnModeChange();
            if (!this.isMobileResolution && isPrivateMode) this.focusPrivateChatOnChange();
        });
    }

    get inputClass() {
        if (!this.newMessage) {
            return this.translate.currentLang ==='he' ? 'input-box-ltr text-indent-left' : 'text-indent-right';
        }
        const isHebrew = this.languageDetectorService.isNoHebrewLetters(this.newMessage[0]);
        if (this.translate.currentLang ==='he') {
            return isHebrew ? 'input-box-ltr text-indent-left' : 'input-box-ltr text-indent-right';
        }
        return isHebrew ? 'text-indent-left' : 'text-indent-right';
    }

    isShowTimeStamp() {
        return !(!this.chatService.isPrivateChatMode);
    }

    getEpochTime(timeToken: string) {
        const time = parseInt(timeToken) / 10000;
        return new Date(time);
    }

    isUserMessage(senderFirebaseUID: string): boolean {
        if (!this.chatService.chatUserToken) return false;
        return senderFirebaseUID === this.chatService.chatUserToken;
    }

    getChatMessageClass(senderFirebaseUID: string): string {
        if (!this.chatService.isPrivateChatMode) return;
        if (!this.isUserMessage(senderFirebaseUID)) {
            if (this.translate.currentLang === 'he') {
                return 'right';
            }
            return 'left';
        }
        if (this.translate.currentLang === 'he') return 'left'
        return 'right';
    }

    listenToChatInputDOMChanges() { // works only if user add an emoji and on first type
        const node = this.chatElement.nativeElement;
        this.domObserver = new MutationObserver(mutations => {
            this.scrollToBottom(SCROLL_CHANGE_TYPE.NEW);
        });
        this.domObserver.observe(node, {
            attributes: false,
            childList: true,
            characterData: false
        });
    }

    async fetchUserDataFromServer() {
        // fake login - for develop purposes only
        if (this.route.snapshot.queryParams && this.route.snapshot.queryParams.token.includes('scrnzPa33_name_')) {
            this.fakeLogin().then();
            return;
        }
        const userFromServer = await this.chatService.fetchUserDataFromServer();
        this.isUserLogged = userFromServer !== null;
        if (userFromServer) {
            await this.initializeUserData(userFromServer).then();
        } else {
            await this.chatService.invokeAnonymousPubNubService(); // get chat messages on start, even if not logged
            this.chatService.retrieveMessageHistory('GENERAL');
        }
    }

    login(userCredential: UserCredential) {
        this.initializeUserData(userCredential.user).then();
    }

    async fakeLogin() {
        // fake login - for develop purposes only
        const fakeUID = this.route.snapshot.queryParams.token;
        const fakePhotoURL = 'https://firebasestorage.googleapis.com/v0/b/screenz-video.appspot.com/o/pics%2FrNR2jfxlIgXMTIa01jJVzUWNTu12?alt=media&token=65671e87-6dad-4e6c-8495-9a75af116ae5';
        const displayName = fakeUID.replace('scrnzPa33_name_', '');
        this.chatService.setChatUserData(displayName, fakePhotoURL, fakeUID);
        await this.chatService.invokeLoggedPubNubService();
        this.chatService.retrieveMessageHistory('GENERAL');
        this.isUserLogged = true;
        this.isLogginPhase = false;
    }

    async initializeUserData(userLogin) {
        await this.googleStorageService.getUserPhotoURL(userLogin)
            .subscribe(async photoURL => {
                this.chatService.setChatUserData(userLogin.displayName, photoURL, userLogin.uid)
                if (this.isUserLogged) {
                    await this.chatService.invokeLoggedPubNubService();
                    this.chatService.retrieveMessageHistory('GENERAL');
                } else {
                    await this.chatService.addPrivateChannelToPubNub();
                }
                this.isUserLogged = true;
                this.isLogginPhase = false;
            });
    }

    sendMessage() {
        if (!this.isUserLogged) {
            this.openLoginWindow();
            return;
        }
        if (!this.newMessage || this.newMessage === '') return;
        this.chatService.sendMessage(this.newMessage.trim());
        this.newMessage = '';
    }

    openLoginWindow() {
        this.isLogginPhase = true;
    }

    scrollToBottom(mode: string): void {
        if (mode === SCROLL_CHANGE_TYPE.CHANGE) {
            setTimeout(_ => {
                this.chatElement.nativeElement.scrollTop = this.chatElement.nativeElement.scrollHeight;
            }, 200);
            return;
        }
        let percentage = this.isMobileResolution ? 0.7 : 0.75;
        if (this.chatService.messages && this.chatService.messages.length < 10) {
            percentage = 0;
        }
        const listHeight = this.chatElement.nativeElement.scrollHeight - this.chatElement.nativeElement.clientHeight;
        const scrollFromTop = this.chatElement.nativeElement.scrollTop;
        if (scrollFromTop / listHeight === 0 || scrollFromTop / listHeight > percentage) {
            this.chatElement.nativeElement.scrollTop = this.chatElement.nativeElement.scrollHeight;
        }
    }

    addEmoji($event) {
        this.isEmojiWindowOpen = false;
        const elm = (document.getElementById('newMessageInput') as HTMLInputElement);
        this.caretPosition = elm.selectionStart;
        this.newMessage = this.newMessage.slice(0, this.caretPosition) + $event.emoji.native + this.newMessage.slice(this.caretPosition);
        setTimeout(() => elm.focus(), 250)
    }

    openEmojiWindow() {
        this.isEmojiWindowOpen = true;
    }

    closeEmojiWindow() {
        this.isEmojiWindowOpen = false;
    }

    // backgroundImageFn = (set: string, sheetSize: number) =>  // outside storage of ngx-emoji emojis in text input
    //     this.backgroundUrl.replace(/\$\{set\}/g, set).replace(/\$\{sheetSize\}/g, String(sheetSize))

    backgroundImageFn = (set: string, sheetSize: number) => // local storage of ngx-emoji emojis in text input
        this.backgroundUrl = '../../../assets/front/img/defaultemojisheet.png';

    backgroundUrlChanged() {
        // Simply clone the current backgroundImageFn to make the pipe call it again immediately
        // (otherwise we'd have to wait until some other param is changed):
        this.backgroundImageFn = this.backgroundImageFn.bind(this);
    }

    setChatHeight() {
        if (this.isMobileResolution) {
            this.setInitialMobileChatHeight();
            if (document.activeElement !== document.getElementById('newMessageInput')) {
                setTimeout(() => document.getElementById('page-main-container').scrollTop = 0, 200);
            }
            return;
        }
        const percentage = 0.7;
        const windowHeight = Math.round(window.innerHeight * percentage) - 61 - 40; // minus chat header and footer size
        this.chatElement.nativeElement.style.height = windowHeight + 'px';
        (document.querySelector('.scrnz-chat-container') as HTMLElement).style.visibility = 'initial';
    }

    setInitialMobileChatHeight() {
        const wrapper = document.querySelector('.wrapper').clientHeight;
        const header = document.querySelector('.scrnz-header').clientHeight;
        const height1 = document.querySelector('.player-container').clientHeight;
        const audienceHeight = document.querySelector('.chatpic-container').clientHeight; // audience
        this.mobileChatHeight = wrapper - (header + height1 + audienceHeight);
        this.audienceHeight = audienceHeight;
        (document.querySelector('.scrnz-chat-container') as HTMLElement).style.height = this.mobileChatHeight + 'px';
        (document.querySelector('.scrnz-chat-container') as HTMLElement).style.visibility = 'initial';
        document.querySelector('.chat-container').classList.remove('overflow-hidden');
    }

    setMobileChatHeightOnModeChange() {
        if (this.chatService.isPrivateChatMode) {
            (document.querySelector('.scrnz-chat-container') as HTMLElement).style.height = (this.mobileChatHeight + this.audienceHeight) + 'px';
            if (!this.chatElement.nativeElement.classList.contains('mobile-private-chat-height')) {
                this.chatElement.nativeElement.classList.add('mobile-private-chat-height');
            }

            return;
        }
        (document.querySelector('.scrnz-chat-container') as HTMLElement).style.height = this.mobileChatHeight + 'px';
        if (this.chatElement.nativeElement.classList.contains('mobile-private-chat-height')) {
            this.chatElement.nativeElement.classList.remove('mobile-private-chat-height');
        }
    }

    identify(index, uid) {
        return uid.index;
    }

    // Caret position

    keyPressed(keyboardKey: KeyboardEvent) {
        if (keyboardKey.key === 'Enter') {
            this.sendMessage();
        } else {
            const elm = (document.getElementById('newMessageInput') as HTMLInputElement);
            this.caretPosition = elm.selectionStart;
        }
    }

    focusPrivateChatOnChange() {
        this.chatInput.nativeElement.focus();
    }

    ngOnDestroy() {
        this.chatService.resetService();
    }

    // storage: input with emoji functions

    // onTypeText($event) {
    //     if ($event.data !== null) {
    //         this.setCaretPosition();
    //         if (document.getElementById('newMessageInput').innerHTML.length < this.newMessageCharLimit) {
    //             this.newMessage += $event.data;
    //         } else {
    //             $event.preventDefault();
    //         }
    //         this.convertToString();
    //     } else {
    //         this.caretPosition -= 1;
    //         // this.newMessage = this.chatService.typeEvent(this.newMessage, this.caretPosition);
    //     }
    //     // const emojiRegex = new RegExp('(\\:\\w+\\:|\\<[\\/\\\\]?3|[\\(\\)\\\\\\D|\\*\\$][\\-\\^]?[\\:\\;\\=]|[\\:\\;\\=B8][\\-\\^]?[3DOPp\\@\\$\\*\\\\\\)\\(\\/\\|])(?=\\s|[\\!\\.\\?]|$)', 'g');
    //     // const emojiRegex = new RegExp(':\\)|:-\\)|:\\(|:-\\(|;\\);-\\)|:-O|8-|:P|:D|:O|:o|:\\||:S|:\\$|:@|8o\\||\\+o\\(|\\(H\\)|\\(C\\)|\\(\\?\\)', 'g');
    // }
    //
    // convertToString() {
    //     document.getElementById('newMessageInput').innerHTML = this.newMessage
    //     for emoji purposes in the future
    //     const children = this.newChatMessage.nativeElement.childNodes;
    //     const data = {
    //         message: this.newMessage,
    //         tempEmoji: this.tempEmoji,
    //         caretPosition: this.caretPosition,
    //         elementName: 'newMessageInput'
    //     }
    //     this.newMessage = this.chatService.convertMessageToString(children, data);
    //     document.getElementById('newMessageInput').innerHTML = twemoji.parse(this.newMessage, {
    //         folder: 'svg',
    //         ext: '.svg'
    //     });
    //     this.tempEmoji = '';
    //     setTimeout(() => this.moveCaret());
    // }

    // Caret positions

    // keyPressed(keyboardKey: KeyboardEvent) {
    //     if (keyboardKey.key === 'Enter') {
    //         this.sendMessage();
    //     } else {
    //         // this.setCaretPosition();
    //         const elm = (document.getElementById('newMessageInput') as HTMLInputElement);
    //         this.caretPosition = elm.selectionStart;
    //     }
    //     // if (keyboardKey.code === 'Space' || keyboardKey.code === 'Insert' || keyboardKey.code === 'Backspace' || keyboardKey.code === 'Delete') {
    //     //     // this.newMessage = document.getElementById('newMessageInput').innerHTML;
    //     //     return;
    //     // } else {
    //     //     if (keyboardKey.key === 'Enter') {
    //     //     }
    //     // }
    // }
    //
    // setCaretPosition() {
    //     document.getElementById('newMessageInput').focus();
    //     let _range = document.getSelection().getRangeAt(0)
    //     let range = _range.cloneRange()
    //     range.selectNodeContents(document.getElementById('newMessageInput'));
    //     range.setEnd(_range.endContainer, _range.endOffset)
    //     this.caretPosition = range.toString().length;
    // }
    //
    // getCurrentElementForCaret(children: ChildNode[]) {
    //     // not finished
    //     // i want to find the exact position of the caret in the node element
    //     // as well as the node element index
    //     let messageLength = 0;
    //     let selectedElementDx;
    //
    //     const elementsLengthArr = children.map(child => {
    //         if (child.nodeType === Node.TEXT_NODE) {
    //             messageLength = messageLength + child.nodeValue.length - 1;
    //             return child.nodeValue.length - 1;
    //         }
    //         if (child.nodeType === Node.ELEMENT_NODE) {
    //             messageLength++;
    //             return 1;
    //         }
    //     });
    //
    //     let buffer = 0;
    //     for (let i = 0; i < elementsLengthArr.length; i++) {
    //         if (i === 0 && this.caretPosition - 1 <= elementsLengthArr[i]) {
    //             selectedElementDx = i;
    //             break;
    //         }
    //
    //         if (i > 0 && this.caretPosition - 1 - buffer === 0) {
    //             selectedElementDx = i;
    //             break;
    //         }
    //
    //         buffer += elementsLengthArr[i];
    //     }
    //
    //     // REMEMBER: this.caretPosition -1 in order to get position in the string
    // }
    //
    // moveCaret() {
    //     const element = document.getElementById('newMessageInput');
    //     const children = Array.from(element.childNodes);
    //     // this.getCurrentElementForCaret(children);
    //     const lastChild = children[children.length - 1];
    //     if (!lastChild) return;
    //     // const angElement = this.newChatMessage.nativeElement;
    //     const range = document.createRange();
    //
    //     if (lastChild.nodeType === Node.TEXT_NODE) {
    //         const isCaretPositionBigger = this.caretPosition > element.lastChild.textContent.length;
    //         const sel = window.getSelection();
    //         range.setStart(element.lastChild, element.lastChild.textContent.length);
    //         range.collapse(true);
    //         sel.removeAllRanges();
    //         sel.addRange(range);
    //     } else {
    //
    //     }
    //
    //     if (element.lastChild === null) return;
    //     var nodeStack = [element];
    //     let node;
    //     let foundStart = false
    //     let stop = false;
    //
    //     element.focus();
    // }
    //
    // handlePaste(e) {
    //     e.stopPropagation();
    //     e.preventDefault();
    //     // if (document.getElementById('newMessageInput').innerHTML.length >= this.newMessageCharLimit) {
    //     //     return;
    //     // }
    //     // this.setCaretPosition();
    //     this.tempEmoji = e.clipboardData.getData('Text');
    //     this.newMessage += e.clipboardData.getData('Text');
    //     this.convertToString();
    // }
}

declare var twemoji: {
    convert: { fromCodePoint(str: string): string; }
    parse(str: string, options?: { folder: string, ext: string }): string;
}
