import {
    AfterViewInit,
    Component,
    ElementRef,
    HostListener,
    Inject,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {WatchComponent} from './watch.component';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {ViewStatsService} from '../../services/view-stats.service';
import {EventStatusService} from '../../services/event-status.service';
import {AuthService} from '../../services/auth.service';
import {LayoutComponent} from '../../layout/layout.component';
import {CurrentBroadcastService} from '../../shared/services/current-broadcast.service';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {ChatService} from '../../shared/components/chat/chat.service';
import {LanguageDetectorService} from '../../services/language-detector.service';
import {EnvService} from '../../services/env.service';
import {BehaviorSubject} from 'rxjs';

@Component({
    selector:    'app-watch-mobile',
    templateUrl: './watch.component.mobile.pug',
    styleUrls:   ['./watch.component.mobile.scss']
})

export class WatchComponentMobile extends WatchComponent implements AfterViewInit{
    isMobileChatShow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    isShowMobileChatpicContainer = false;

    constructor(
        @Inject(LayoutComponent)
        private layout: LayoutComponent,
        public route: ActivatedRoute,
        router: Router,
        http: HttpClient,
        public sanitizer: DomSanitizer,
        public translate: TranslateService,
        public viewStatsServer: ViewStatsService,
        public eventStatus: EventStatusService,
        public auth: AuthService,
        public currentBroadcastService: CurrentBroadcastService,
        public titleService: Title,
        public chatService: ChatService,
        public languageDetectorService: LanguageDetectorService,
        public env: EnvService
    )
    {
        super(route, router, http, sanitizer, translate,viewStatsServer,eventStatus,auth, currentBroadcastService, titleService, languageDetectorService, env);
        window.scrollTo(0,0);
    }
    clickOpen() {
        this.layout.drawer.toggle();
    }

    calculateHeightWidth(){
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.calculateHeightWidth()
    }

    ngAfterViewInit(): void {
        setTimeout(()=>{
            this.isMobileChatShow$.subscribe(is => this.isShowMobileChatpicContainer = is);
            this.calculateHeightWidth();
        },1000)
    }

    notifyIsMobileChatShow(evt) {
        this.isMobileChatShow$.next(!!evt);
    }
}
