import {Component, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {AuthService} from '../../services/auth.service';
import {CurrentBroadcastService} from '../../shared/services/current-broadcast.service';
import {EnvService} from '../../services/env.service';


interface EventData {
	id: string;
	name: string;
	password: string;
	description: string;
	external_id: string;
	external_url: string;
	pictureUrl: string;
	startTime: Date;
	endTime: Date;
	created: Date;
}

@Component({
	selector: 'app-home',
	templateUrl: './home.component.pug',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnDestroy {

	eventData$: Observable<EventData[]>;
	$destroyed = new Subject();


	constructor(private http: HttpClient, public translate: TranslateService, private auth: AuthService, public currentBroadcastService: CurrentBroadcastService, private env: EnvService) {
		window.location.href = 'https://stories.sc/storycards/N5TyFbERJ/';
		// const url = `${this.env.apiUrl}/api/stream`;
		// this.eventData$ = http.get<EventData[]>(url);
		// this.currentBroadcastService.setCurrentShow(null, null, null);
	}

	ngOnDestroy() {
		this.$destroyed.next();
	}

	login(provider) {
        this.auth.login(provider);
	}

	async logout() {
	    await this.auth.signOut().then();
    }

    async statusO() {
		const r = await this.auth.getUser();
		console.log('000r', r, 'is Logged? ', !!r);
	}
}
