import {Component, HostListener, OnInit} from '@angular/core';
import {AudienceViewerStats, ChatService} from '../chat/chat.service';
import {PubNubService, PubNubUserInfo} from '../chat/pubnub.service';
import {filter} from 'rxjs/operators';
import {LanguageDetectorService} from '../../../services/language-detector.service';

@Component({
    selector: 'app-audience',
    templateUrl: './audience.component.pug',
    styleUrls: ['./audience.component.scss']
})
export class AudienceComponent implements OnInit {

    @HostListener('window:resize', ['$event'])
    onResize() {
        const newContainerWidth = (document.querySelector('.audience-container') as HTMLElement).offsetWidth;
        if (this.containerWidth * 1.2 < newContainerWidth || newContainerWidth < this.containerWidth * 0.8) {
            this.listOfUsers = [];
            this.prepareArrays([...this.rawAudienceUsers]);
        }
    }

    containerWidth: number;
    amountOfLines: number;
    amountPerRow: number;
    listOfUsers: PubNubUserInfo[][];
    rawAudienceUsers: PubNubUserInfo[];
    listOfPrivateChattedWith: { [userToken: string]: AudienceViewerStats } = {};
    localListOfPrivateChattedWith: { [userToken: string]: AudienceViewerStats } = {};
    isMobileResolution = false;

    constructor(public chatService: ChatService, public pubNubService: PubNubService, public languageDetectorService: LanguageDetectorService) {
        this.isMobileResolution = window.innerWidth < 900;
    }

    ngOnInit(): void {
        this.getListOfChattedWithUsers();
        this.pubNubService.chatUsers$.pipe(
            filter(x => x.length > 0)
        ).subscribe(users => {
            this.listOfUsers = [];
            users.forEach(viewer => {
                if (this.chatService.chatUserToken !== viewer.firebaseUID && !this.listOfPrivateChattedWith[viewer.firebaseUID]) {
                    if (this.localListOfPrivateChattedWith && this.localListOfPrivateChattedWith[viewer.firebaseUID]) {
                        this.listOfPrivateChattedWith[viewer.firebaseUID] = this.localListOfPrivateChattedWith[viewer.firebaseUID];
                    } else {
                        this.listOfPrivateChattedWith[viewer.firebaseUID] = {
                            firebaseUID: viewer.firebaseUID,
                            isChattedWith: false,
                            isCurrentlyChatting: false,
                            amountOfUnreadMessages: 0
                        }
                    }
                }
            });
            this.rawAudienceUsers = [...users];
            this.prepareArrays([...users]);
        });
        this.chatService.audienceNewPrivateMessage$.subscribe((status) => {
            this.updateViewerStatus(status);
        });

    }

    getListOfChattedWithUsers() {
        this.chatService.loginProcessEnded$.subscribe(_ => {
            this.localListOfPrivateChattedWith = this.chatService.getUsersChattedWithLog();
        })
    }

    updateViewerStatus(status: AudienceViewerStats) {
        const selectedUserUID = this.chatService.privateChatUserData ? this.chatService.privateChatUserData.firebaseUID : null;
        const isCurrentlyChatting = status.firebaseUID === selectedUserUID;
        if (!this.listOfPrivateChattedWith[status.firebaseUID] && status.firebaseUID !== this.chatService.chatUserToken) {
            this.listOfPrivateChattedWith[status.firebaseUID] = status;
        }

        if (status.firebaseUID === this.chatService.chatUserToken) {
            this.updateUserLogStorage(this.chatService.privateChatUserData.firebaseUID);
            return;
        }

        let amountOfUnreadMessages = isCurrentlyChatting ? 0 : this.listOfPrivateChattedWith[status.firebaseUID].amountOfUnreadMessages + 1;
        if (status.amountOfUnreadMessages === 0) { // already read it
            amountOfUnreadMessages = 0;
        }

        let isChattedWith = false;
        if (this.chatService.privateMessageLog[status.firebaseUID] && this.chatService.privateMessageLog[status.firebaseUID].length > 0) {
            isChattedWith = true;
        }

        this.listOfPrivateChattedWith[status.firebaseUID] = {
            firebaseUID: status.firebaseUID,
            isChattedWith,
            isCurrentlyChatting,
            amountOfUnreadMessages
        };

        this.updateUserLogStorage(status.firebaseUID);
        this.listOfPrivateChattedWith = {...this.listOfPrivateChattedWith};
    }

    getDisplayName(user: PubNubUserInfo) {
    	return  (user && user.displayName || '').split(' ')[0] || '';
    }

    prepareArrays(orgArray: PubNubUserInfo[]) {
        const imgWidth = this.isMobileResolution ? 40 : 60;
        const elementWidth = imgWidth + 10 + 10;
        // image width (60) + margin left (10) + margin right (10)
        // image width in mobile is 40px
        this.containerWidth = (document.querySelector('.audience-container') as HTMLElement).offsetWidth;
        this.amountPerRow = Math.floor(this.containerWidth / elementWidth);
        this.amountOfLines = Math.ceil(orgArray.length / this.amountPerRow);
        const arrayWithUser = this.getArrayWithUser(orgArray);
        if (arrayWithUser !== 0) {
            this.listOfUsers.push(arrayWithUser);
        }
        this.setRestOfListOfUsers(orgArray);
    }

    setRestOfListOfUsers(orgArray) {
        if (!orgArray.length) {
            return;
        }
        let arr = [];
        const len: number = this.getMaxAudienceBubblesLength(orgArray)

        for (let i = 0; i < len; i++) {
            if (i % this.amountPerRow === 0 && arr.length > 0) {
                this.listOfUsers.push(arr);
                arr = [];
            }
            const displayName = this.getDisplayName(orgArray[i]);
            if (!displayName || displayName === '' || displayName === undefined || displayName === 'undefined') {
                continue;
            }
            arr.push(orgArray[i]);
            if (i - this.amountPerRow === 0 && i >= len) {
                break;
            }
        }
        this.listOfUsers.push(arr);
    }

    getMaxAudienceBubblesLength(orgArray) {
        if (this.isMobileResolution) {
            return this.getMobileMaxAudienceBubblesLength(orgArray);
        }
        return this.getDesktopMaxAudienceBubblesLength(orgArray);
    }

    getMobileMaxAudienceBubblesLength(orgArray) {
        let len: number;
        if (this.listOfUsers.length > 0) {
            len = orgArray.length > 100 ? 100 : orgArray.length;
        } else {
            len = orgArray.length > 100 ? 101 : orgArray.length;
        }
        return len;
    }

    getDesktopMaxAudienceBubblesLength(orgArray) {
        let len: number;
        if (this.listOfUsers.length > 0) {
            len = orgArray.length > 500 ? 500 : orgArray.length;
        } else {
            len = orgArray.length > 500 ? 501 : orgArray.length;
        }
        return len;
    }

    getArrayWithUser(orgArray) {
        const userElementIndex = this.getUserDxIn(orgArray);

        if (userElementIndex !== -1) {
            let firstArrayWithUserData = orgArray.splice(userElementIndex, 1)
            const otherUsers = orgArray.splice(0, this.amountPerRow - 1);
            return firstArrayWithUserData.concat(otherUsers);
        }
        return 0;
    }

    getUserDxIn(orgArray) {
        return orgArray.findIndex(elm => {
            return elm.firebaseUID === this.chatService.chatUserToken;
        });
    }

    selectUser(user: PubNubUserInfo) {
        if (this.chatService.privateChatUserData && user.firebaseUID === this.chatService.privateChatUserData.firebaseUID) return;
        if (this.chatService.privateChatUserData && this.chatService.privateChatUserData.firebaseUID !== '') {
            this.listOfPrivateChattedWith[this.chatService.privateChatUserData.firebaseUID].isCurrentlyChatting = false;
        }

        this.chatService.selectUserToPrivateChat(user).then(() => {
            const isChattedWith = !!((this.chatService.privateMessageLog && this.chatService.privateMessageLog[user.firebaseUID]) && this.chatService.privateMessageLog[user.firebaseUID].length > 0);
            this.setOtherUserState(user.firebaseUID, isChattedWith, true, 0);
        });
    }

    setOtherUserState(firebaseUID: string, isChattedWith: boolean, isCurrentlyChatting: boolean, amountOfUnreadMessages: number) {
        this.listOfPrivateChattedWith[firebaseUID].isChattedWith = isChattedWith;
        this.listOfPrivateChattedWith[firebaseUID].isCurrentlyChatting = isCurrentlyChatting;
        this.listOfPrivateChattedWith[firebaseUID].amountOfUnreadMessages = amountOfUnreadMessages;
        this.listOfPrivateChattedWith = {...this.listOfPrivateChattedWith};
        if ((this.chatService.privateMessageLog && this.chatService.privateMessageLog[firebaseUID]) && this.chatService.privateMessageLog[firebaseUID].length > 0) {
            this.updateUserLogStorage(firebaseUID);
        }
    }

    updateUserLogStorage(firebaseUID: string) {
        if (firebaseUID === this.chatService.chatUserToken) return;
        let copyOfListOfPrivateChattedWith = {...this.listOfPrivateChattedWith};
        copyOfListOfPrivateChattedWith[firebaseUID].isCurrentlyChatting = false;
        this.chatService.setUsersChattedWithLog(this.listOfPrivateChattedWith);
    }

    identify(index, uid) {
        return uid.index;
    }

    // bubbles

    isCurrentChatting(firebaseUID: string) {
        return this.chatService.isPrivateChatMode && (this.chatService.privateChatUserData && this.chatService.privateChatUserData.firebaseUID === firebaseUID);
    }

    isShowBubble(firebaseUID: string) {
        if (this.chatService.chatUserToken === firebaseUID) return false;
        const isChatted = (this.listOfPrivateChattedWith[firebaseUID].isChattedWith ||
            (this.chatService.privateMessageLog && (this.chatService.privateMessageLog[firebaseUID] && this.chatService.privateMessageLog[firebaseUID].length > 0)));
        const isCurrentChatting = this.isCurrentChatting(firebaseUID);
        const isCurrentChattedOrChatting = isCurrentChatting || isChatted;
        const isHistoryChattedOrChatting =
            (this.localListOfPrivateChattedWith &&
                (this.localListOfPrivateChattedWith[firebaseUID] &&
                    (this.localListOfPrivateChattedWith[firebaseUID].isChattedWith || this.localListOfPrivateChattedWith[firebaseUID].amountOfUnreadMessages > 0)));
        return isCurrentChattedOrChatting || isHistoryChattedOrChatting;
    }

    isShowActiveBubble(firebaseUID: string) {
        if (this.chatService.chatUserToken === firebaseUID) return false;
        const isCurrentChatting = this.isCurrentChatting(firebaseUID);
        // const isChatted =
        //     this.listOfPrivateChattedWith && (this.listOfPrivateChattedWith[firebaseUID] && this.listOfPrivateChattedWith[firebaseUID].isCurrentlyChatting);
        const isHaveUnreadMessages =
            (this.chatService.privateMessageLog && this.chatService.privateMessageLog[firebaseUID]) && this.listOfPrivateChattedWith[firebaseUID].amountOfUnreadMessages > 0 ||
            (this.localListOfPrivateChattedWith && this.localListOfPrivateChattedWith[firebaseUID]) && this.localListOfPrivateChattedWith[firebaseUID].amountOfUnreadMessages > 0;
        return isCurrentChatting || isHaveUnreadMessages;
    }

    isHasUnreadMessages(firebaseUID: string) {
        return !this.listOfPrivateChattedWith[firebaseUID].isCurrentlyChatting && (
            (this.listOfPrivateChattedWith[firebaseUID] && this.listOfPrivateChattedWith[firebaseUID].amountOfUnreadMessages > 0) ||
            (this.localListOfPrivateChattedWith &&
                (this.localListOfPrivateChattedWith[firebaseUID] && this.localListOfPrivateChattedWith[firebaseUID].amountOfUnreadMessages > 0)));
    }

    getAmountOfUnreadMessages(firebaseUID: string) {
        if ((this.localListOfPrivateChattedWith && this.localListOfPrivateChattedWith[firebaseUID]) && this.localListOfPrivateChattedWith[firebaseUID].amountOfUnreadMessages > 0) return this.localListOfPrivateChattedWith[firebaseUID].amountOfUnreadMessages;
        if (this.listOfPrivateChattedWith && this.listOfPrivateChattedWith[firebaseUID]) return this.listOfPrivateChattedWith[firebaseUID].amountOfUnreadMessages;
    }

}
