import {Component, OnDestroy, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {StreamInfo} from '../../models/stream-info.model';
import {catchError, map, shareReplay, takeUntil, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {EventStatusService} from '../../services/event-status.service';
import {EventStatus} from '../../models/event-info';

import {TranslateService} from '@ngx-translate/core';
import {createCountdownOpenEventStream, createEndOfStreamTimeTrigger} from '../../shared/streams/streamUtils';
import {ViewStatsService} from '../../services/view-stats.service';

import '../../../assets/js/embeddedChat';
import {AuthService} from '../../services/auth.service';
import {StreamStatus, VideoPlayerComponent} from '../../shared/components/video-player/video-player.component';

import {CurrentBroadcastService} from '../../shared/services/current-broadcast.service';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {LanguageDetectorService} from '../../services/language-detector.service';
import {EnvService} from '../../services/env.service';


enum StreamUiErrorStates {
	NO_ERROR,
	TINT_SCREEN,
	SHOW_ERROR
}

@Component({
	selector: 'app-watch',
	templateUrl: './watch.component.pug',
	styleUrls: ['./watch.component.scss'],
	// changeDetection: ChangeDetectionStrategy.OnPush
})
export class WatchComponent implements OnDestroy {
	@ViewChild(VideoPlayerComponent, {static: false}) videoPlayerComp: VideoPlayerComponent;
	
	public $videoPlayerStatus: BehaviorSubject<StreamStatus> = new BehaviorSubject(StreamStatus.NOT_STREAMING);
	
	StreamUiErrors = StreamUiErrorStates;
	StreamStates = StreamStatus;
	EventStates = EventStatus;
	
	streamInfo$: Observable<StreamInfo>;
	
	streamError$: Observable<StreamUiErrorStates>;
	
	$destroyed = new Subject();
	countdownToEvent$: Observable<string>;
	isEventAfterEndTime$: Observable<boolean>;
	currentDate: number;
	streamId: string;
	isDesktopChatShow$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);  // do not use this one in watch.mobile
	isShowDesktopChatpicContainer = false;  // do not use this one in watch.mobile
	showErrorOnStreamingTime = 10 * 1000;
	
	constructor(
		public route: ActivatedRoute,
		router: Router,
		http: HttpClient,
		public sanitizer: DomSanitizer,
		public translate: TranslateService,
		public viewStatsServer: ViewStatsService,
		public eventStatus: EventStatusService,
		public auth: AuthService,
		public currentBroadcastService: CurrentBroadcastService,
		public titleService: Title,
		public languageDetectorService: LanguageDetectorService,
		public env: EnvService
	) {
		
		this.streamId = route.snapshot.paramMap.get('streamId');
		const streamToken = route.snapshot.queryParamMap.get('token');
		const url = `${this.env.apiUrl}/api/stream/watch/${this.streamId}?token=${encodeURIComponent(streamToken)}`;
		this.currentBroadcastService.setCurrentShow('watch', this.streamId, streamToken);
		this.streamInfo$ = http.get<StreamInfo>(url)
		                       .pipe(
			                       catchError(() => {
				                       router.navigate(['/notfound']);
				                       throw new Error('Access deined');
			                       }),
			                       map((streamInfo) => {
									   if (streamInfo.redirectUrl) {
										   this.redirectToExternalPage(streamInfo.redirectUrl, streamToken);
										   return;
									   }
				                       streamInfo.playerUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
					                       `${this.env.playerUrl}?streamId=${this.env.millicastAccount}/${streamInfo.eventId}&token=${streamInfo.token}`
				                       );
				                       return streamInfo;
			                       }),
			                       tap(() => {
				                       eventStatus.joinStatusUpdates(this.streamId, streamToken, false);
				                       viewStatsServer.joinStatsUpdates(this.streamId);
			                       }),
			                       tap((streamInfo) => this.titleService.setTitle(streamInfo.title)),
			                       tap((streamInfo) => console.log('si: ', streamInfo)),
			                       shareReplay(1),
			                       takeUntil(this.$destroyed)
		                       );
		this.currentDate = Date.now();
		this.countdownToEvent$ = createCountdownOpenEventStream(eventStatus, translate, this.streamInfo$);
		this.isEventAfterEndTime$ = createEndOfStreamTimeTrigger(eventStatus, this.streamInfo$);
		this.isDesktopChatShow$.subscribe(is => this.isShowDesktopChatpicContainer = is);
	}
	
	ngOnDestroy() {
		this.$destroyed.next(true);
	}

	redirectToExternalPage(url: string, streamToken: string) {
		window.location.href = `${url}/live/watch/${this.streamId}?token=${encodeURIComponent(streamToken)}`;
	}
	
	async logout() {
		await this.auth.signOut().then();
	}
	
	getStreamSource(streamInfo, streamType) {
		switch (streamType) {
			case 'EXTERNAL_STREAM':
				return streamInfo.sourceUrl;
			case 'RTMP':
				return streamInfo.hlsEndpoint;
			default:
				// TODO: Process the error condition
				return ``;
		}
	}

	notifyIsDesktopChatShow(evt) { // do not use this one in watch.mobile
		this.isDesktopChatShow$.next(!!evt);
	}
}
