import {Injectable} from '@angular/core';
import * as io from 'socket.io-client';
import {BehaviorSubject, Subject} from 'rxjs';
import {EventStatus, WatchEventStatus} from '../models/event-info';
import {EnvService} from './env.service';

enum SOCKET_COMMANDS {
	JOIN_EVENT = 'JOIN_EVENT',
}

interface LastJoinInfo {
	streamId: string;
	userToken: string;
}

enum SOCKET_EVENTS {
	EVENT_STATUS_CHANGE = 'EVENT_STATUS_CHANGE',
	STREAM_STATUS_CHANGE = 'STREAM_STATUS_CHANGE',
	EVENT_NO_ACCESS = 'EVENT_NO_ACCESS'
}

@Injectable({
	providedIn: 'root'
})

export class EventStatusService {
	lastJoinInfo: LastJoinInfo | null = null;
	eventId: string = '';
	
	$updates: Subject<WatchEventStatus> = new BehaviorSubject<WatchEventStatus>({
		status: EventStatus.LOADING,
		streamType: 'WEB_RECORDER'
	});
	streamStatus$: Subject<boolean> = new BehaviorSubject<boolean>(false);
	socket: SocketIOClient.Socket;
	
	constructor(private env: EnvService) {
	}
	
	updateStatusLocally(msg: WatchEventStatus) {
		this.$updates.next(msg);
	}
	
	joinStatusUpdates(streamId: string, userToken: string, isCaster: boolean = false) {
		if (this.lastJoinInfo) {
			if (this.lastJoinInfo.streamId == streamId && this.lastJoinInfo.userToken == userToken) {
				return;
			}
		}
		
		if (this.socket) {
			this.socket.disconnect();
			this.socket = null;
		}
		
		this.lastJoinInfo = {streamId, userToken};
		
		this.socket = io(this.env.socketServer + '/status');
		this.socket.emit(SOCKET_COMMANDS.JOIN_EVENT, streamId, userToken, isCaster);
		this.socket.on('reconnect', () => {
			this.socket.emit(SOCKET_COMMANDS.JOIN_EVENT, streamId, userToken, isCaster);
		});
		
		this.socket.on(SOCKET_EVENTS.EVENT_NO_ACCESS, (msg) => {
			console.log('No access Message: ', msg);
			this.$updates.next({status: EventStatus.NO_ACCESS, streamType: 'WEB_RECORDER'});
		});
		
		this.socket.on(SOCKET_EVENTS.EVENT_STATUS_CHANGE, (msg: any) => {
			console.log('WS Message: ', msg);
			this.$updates.next(msg);
		});
		
		this.socket.on(SOCKET_EVENTS.STREAM_STATUS_CHANGE, (msg: any) => {
			console.log('Stream status change: ', msg);
			this.streamStatus$.next(msg);
			// this.$updates.next(msg.status);
		});
		
	}
}
