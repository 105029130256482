import {AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from '@angular/core';
import {MultiStreamsMixer} from './MultiStreamsMixer';
import {BehaviorSubject, Subject} from 'rxjs';
import {WebRTCEvent} from '../../../models/webrtc-event';
import {MatSnackBar} from '@angular/material/snack-bar';
import {takeUntil} from 'rxjs/operators';
import {MillicastService} from '../../services/millicast.service';


declare global {
	// noinspection JSUnusedGlobalSymbols
	interface MediaDevices {
		getDisplayMedia(mediaConstraints: MediaStreamConstraints);
	}
	
	interface MediaStream {
		width: number;
		height: number;
		left: number;
		top: number;
		fullcanvas: boolean;
	}
}

export enum WebRecorderStatus {
	INIT = 'INIT',
	READY = 'READY',
	ERROR = 'ERROR'
}

@Component({
	selector: 'app-video-recorder',
	templateUrl: './video-recorder.component.pug',
	styleUrls: ['./video-recorder.component.scss']
})
export class VideoRecorderComponent implements AfterViewInit, OnDestroy {
	public $recorderStatus: BehaviorSubject<WebRecorderStatus> = new BehaviorSubject(WebRecorderStatus.INIT);
	
	@ViewChild('localVideo') videoEl: ElementRef;
	
	@Input() streamId: string;
	@Input() wsUrl: string;
	
	@Output() onEvent = new EventEmitter<WebRTCEvent>();

	/*
	10-8-20
	I changed broadcaster's settings back to the old ones,
	due to an issue (slowness) with the new ones.
	*/
	mediaConstraints: MediaStreamConstraints = {
		video: {
			width: 640,
			height: 360,
			frameRate: 20
			// width: {min: 640, max: 1920, ideal: 1280},
			// height: {min: 360, max: 1080, ideal: 720},
			// frameRate: {min: 10, max: 60, ideal: 24},
		},
		audio: true
	};
	
	isPublishing = false;
	// canShareScreen = false;
	isScreenSharing = false;
	
	micGainNode: GainNode;
	localStream: MediaStream;
	localVideoSettings: MediaTrackSettings;
	desktopStream: MediaStream;
	mixer: MultiStreamsMixer;
	mixedStream: MediaStream;
	muted = false;
	$destroyed = new Subject();
	playerError = false;
	
	constructor(private webrtcService: MillicastService, private snackBar: MatSnackBar) {
		this.webrtcService.events$
		    .pipe(
			    takeUntil(this.$destroyed)
		    )
		    .subscribe(ev => this.onRtcEvent(ev));
	}
	
	ngOnDestroy() {
		if (this.mixer) {
			this.mixer.releaseStreams();
		}
		this.endStream(this.localStream);
		this.endStream(this.desktopStream);
		this.$destroyed.next(true);
	}
	
	ngAfterViewInit() {
		this.startVideoStream()
		    .then(() => this.onEvent.next({event: 'ready'}))
		    .catch((e) => {
			    console.error('Error opening camera: ', e);
			    this.$recorderStatus.next(WebRecorderStatus.ERROR);
			    this.playerError = true;
		    });
	}
	
	endStream(stream?: MediaStream) {
		if (stream) {
			stream.getTracks().forEach(track => {
				track.stop();
				stream.removeTrack(track);
			});
		}
	}
	
	async startVideoStream() {
		this.isScreenSharing = false;
		
		this.endStream(this.desktopStream);
		this.desktopStream = null;
		
		if (!this.localStream) {
			this.localStream = await navigator.mediaDevices.getUserMedia(this.mediaConstraints);
			this.localVideoSettings = this.localStream.getVideoTracks()[0].getSettings();
			console.log('Video stream settings: ', this.localVideoSettings);
			this.localStream.width = this.localVideoSettings.width;
			this.localStream.height = this.localVideoSettings.height;
			this.localStream.fullcanvas = true;
			await this.setupAudioStream(this.localStream);
		}
		if (!this.mixer) {
			this.mixer = new MultiStreamsMixer([this.localStream]);
			this.mixer.frameInterval = 66;
			this.mixedStream = this.mixer.getMixedStream();
			this.mixer.resetVideoStreams([this.localStream]);
			this.videoEl.nativeElement.srcObject = this.mixedStream;
			this.videoEl.nativeElement.muted = true;
			// this.webrtcService.connect();
		} else {
			// Reset camera to full size
			delete this.localStream.left;
			delete this.localStream.top;
			this.mixer.width = this.localStream.width = this.localVideoSettings.width;
			this.mixer.height = this.localStream.height = this.localVideoSettings.height;
			this.localStream.fullcanvas = true;
			this.mixer.resetVideoStreams([this.localStream]);
		}
		
		this.$recorderStatus.next(WebRecorderStatus.READY);
		this.mixer.startDrawingFrames();
		
		return true;
	}
	
	async setupAudioStream(stream: MediaStream) {
		const audioTracks = stream.getAudioTracks();
		if (audioTracks.length) {
			stream.removeTrack(audioTracks[0]);
		}
		if (this.mediaConstraints.audio) {
			const audioConstraint: MediaStreamConstraints = {
				audio: this.mediaConstraints.audio
			};
			const audioStream = await navigator.mediaDevices.getUserMedia(audioConstraint);
			stream.addTrack(audioStream.getAudioTracks()[0]);
		}
	}
	
	async onScreenShare() {
		this.desktopStream = await navigator.mediaDevices.getDisplayMedia({video: true, audio: true});
		console.log('Desktop settings: ', this.desktopStream.getVideoTracks()[0].getSettings());
		this.desktopStream.getVideoTracks()[0].onended = this.onShareEnd.bind(this);
		this.desktopStream.fullcanvas = true;
		this.desktopStream.width = window.screen.width;
		this.desktopStream.height = window.screen.height;
		
		this.localStream.fullcanvas = false;
		this.localStream.width = Math.floor(this.desktopStream.width * 0.15);
		this.localStream.height = Math.floor(this.desktopStream.height * 0.15);
		this.localStream.left = this.desktopStream.width - this.localStream.width;
		this.localStream.top = this.desktopStream.height - this.localStream.height;
		
		this.mixer.resetVideoStreams([this.desktopStream, this.localStream]);
		this.isScreenSharing = true;
	}
	
	async onShareEnd() {
		this.isScreenSharing = false;
		await this.startVideoStream();
	}
	
	async muteAudio() {
		this.mixedStream.getAudioTracks()[0].enabled = this.muted;
		this.muted = !this.muted;
	}
	
	publish() {
		console.log('publish called. Url: ', this.wsUrl);
		this.webrtcService.publish(this.wsUrl, this.streamId, this.mixedStream);
	}
	
	endPublish() {
		this.webrtcService.endPublish();
	}
	
	onRtcEvent(event: WebRTCEvent) {
		switch (event.event) {
			case 'notification':
				this.onEvent.emit(event);
				break;
			case 'error':
				this.snackBar.open(event.data.definition, 'OK', {duration: 2000});
				break;
		}
	}
}
