import {combineLatest, Observable, timer} from "rxjs";
import {distinctUntilChanged, filter, map, shareReplay, startWith, switchMapTo, tap} from "rxjs/operators";
import {EventStatus} from "../../models/event-info";
import {formatDateToCountdown} from "../operators/formatDateToCountdown";
import {EventStatusService} from "../../services/event-status.service";
import {StreamInfo} from "../../models/stream-info.model";
import {TranslateService} from "@ngx-translate/core";

//TODO: Clean this code up, refactor/re-use

export function createCountdownOpenEventStream(eventStatusService: EventStatusService, translate: TranslateService, streamInfo$: Observable<StreamInfo>) {
    let localStartTime:Date;
    return combineLatest([eventStatusService.$updates, streamInfo$])
        .pipe(
            filter(([eventStatus, info]) => eventStatus.status == EventStatus.PREPARATION_STATE),
            map(([eventState, info]) => info),
            tap(info => localStartTime = info.startTime),
            switchMapTo(timer(0, 1000)),
            map(() => localStartTime),
            formatDateToCountdown(translate),
            distinctUntilChanged(),
        );
}


export function createEndOfStreamTimeTrigger(eventStatusService: EventStatusService, streamInfo$: Observable<StreamInfo>, secondsDeltaFromEndTime:number = 0) {
    let endTimeLocal:Date;

    return combineLatest([eventStatusService.$updates, streamInfo$])
        .pipe(
            filter(([eventStatus, info]) => eventStatus.status == EventStatus.LIVE_ONAIR),
            map(([eventState, info]) => info),
            tap(info => endTimeLocal = new Date(info.endTime.getTime() + secondsDeltaFromEndTime*1000)),
            switchMapTo(timer(0, 1000)),
            //tap(tick => console.log(`Tick: ${tick} + endTime: ${endTimeLocal} / ${new Date()}`)),
            map(() => endTimeLocal > new Date() ? false : true),
            distinctUntilChanged(),
            shareReplay(1)
        );
}


export function createCountdownEndEventStream(eventStatusService: EventStatusService, translate: TranslateService, streamInfo$: Observable<StreamInfo>, timeBeforeEndSeconds:number = -1) {
    let localEndTime:Date;
    return combineLatest([eventStatusService.$updates, streamInfo$])
        .pipe(
            filter(([eventStatus, info]) => eventStatus.status == EventStatus.LIVE_ONAIR),
            map(([eventState, info]) => info),
            tap(info => localEndTime = info.endTime),
            switchMapTo(timer(0, 1000)),
            filter(() => timeBeforeEndSeconds == -1 || (new Date() > new Date(localEndTime.getTime() - timeBeforeEndSeconds*1000))),
            map(() => localEndTime),
            formatDateToCountdown(translate),
            startWith('END_COUNTDOWN'),
            distinctUntilChanged()
        );
}
