import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FileHandlerService} from '../../../services/file-handler.service';
import {NgxDropzoneComponent, NgxDropzoneVideoPreviewComponent} from 'ngx-dropzone';
import {SafeUrl} from '@angular/platform-browser';
import {MatSnackBar} from '@angular/material/snack-bar';
import {HttpClient} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';


@Component({
	selector: 'app-file-upload',
	templateUrl: './file-upload.component.pug',
	styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit {
	@ViewChild('fileInput', {static: false}) fileInput: ElementRef;
	@Input() eventId: string;
	@Input() token: string;
	@ViewChild(NgxDropzoneComponent) dropzoneComponent: NgxDropzoneComponent;

	@Output() videoImported = new EventEmitter<{status: string, source: SafeUrl}>();

	progress = 0;
	isUploading = false;
	currentId: string = null;
	
	
	constructor(private fileHandlerService: FileHandlerService,
				public snackbar: MatSnackBar,
				public http: HttpClient,
				public translate: TranslateService,
				) {
		this.fileHandlerService.progressSource.subscribe(progress => this.progress = progress);
	}

	files: File[] = [];

	onSelect (event) {
		// todo what if wrong file type or more than one
		if (event.rejectedFiles.length > 0){
			if (8591008334 < event.rejectedFiles[0].size) {
				this.snackbar.open('An error occurred, maximum video file size is 8GB', "Got it");
				return;
			}
			this.snackbar.open('An error occurred, please select video file only', "Got it");
		}
		if(event.addedFiles.length > 0){
			this.files.push(...event.addedFiles);
			this.setVideoDuration(this.files[0]);
		}
	}

	setVideoDuration(file) {
		const video = document.createElement('video');
		video.preload = 'metadata';
		let t = this;
		video.onloadedmetadata = function () {
			window.URL.revokeObjectURL(video.src);
			t.upload(video.duration);
		}

		video.src = URL.createObjectURL(file);
	}

	upload(duration) {
		const formData = new FormData();
		formData.append('file', this.files[0]);
		formData.append('filename', this.files[0].name);
		formData.append('duration', JSON.stringify(duration));
		this.fileHandlerService.upload(`stream/${this.eventId}/simulive?token=${encodeURIComponent(this.token)}`, formData).subscribe();
	}

	onRemove(event) {
		console.log(event);
		this.files.splice(this.files.indexOf(event), 1);
	}


	ngOnInit() {
	}
	
	onClick() {
		this.fileInput.nativeElement.click();
	}
	
	inputOnSelect(event) {
		const file = event.target.files[0];
		this.uploadImage(file.name, file);
	}
	
	uploadImage(filename: string, file: File) {
		this.isUploading = true;
		const formData = new FormData();
		formData.append('fileName', filename);
		formData.append('file', file);
		this.fileHandlerService.upload(`stream/${this.eventId}?token=${encodeURIComponent(this.token)}`, formData)
		    .subscribe(res => {
			    this.progress = 0;
			    this.isUploading = false;
			    this.currentId = res;
		    });
	}
}
